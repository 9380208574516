import * as React from 'react';
import { useState, useEffect } from 'react';
import * as WebBlocks from '../WebBlocks/WebBlocks';
import { graphql } from 'gatsby';
// import * as styles from './WebBlock.module.scss'

export interface IWebBlockProps{
    contentType: string;
    blockProps: any & ICommonWebBlockProps;
    gridRow?: number;
    params?: any;
}

export interface ICommonWebBlockProps{
    style?: React.CSSProperties;
    Grid_x0020_column_x0020_start?: number;
    Grid_x0020_column_x0020_end?: number;
}

export const WebBlock = (props: IWebBlockProps) => {
    const { contentType, blockProps, gridRow, params } = props;
    const { Grid_x0020_column_x0020_start, Grid_x0020_column_x0020_end } = blockProps;

    const blockContentType = contentType.replace(/ /g,'').replace(/-/g, '_');
    const allBlocks: any = WebBlocks;
    const availableBlockKeys = Object.keys(allBlocks);

    const [error, setError] = useState<string>(null);
    const [Block, setBlock] = useState(null);

    const containerStyle = Grid_x0020_column_x0020_start !== undefined && Grid_x0020_column_x0020_end !== undefined ? 
        { 
            gridColumn: `${Grid_x0020_column_x0020_start} / ${Grid_x0020_column_x0020_end}` ,
            gridRow
        } : {};

    return (
        <>
        {
            availableBlockKeys.indexOf(blockContentType) >= 0 ? [blockContentType].map((v,i) => {
                const FoundBlock = allBlocks[v];
                
                return(
                    <FoundBlock {...blockProps} key={`heroBlock-${i}`} style={ containerStyle } params={ params } />
                );
            }) : (
                <span> Block not found for content type: { contentType }</span>
            )
        }
        </>
    )
} 